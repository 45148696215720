<template>
  <div>
    <van-field
      ref="field"
      :required="required"
      v-model="model"
      :type="type"
      :size="size"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :show-word-limit="showWordLimit"
      :autosize="autosize"
      :disabled="disabled"
      :readonly="readonly"
      :input-align="inputAlign"
      :right-icon="rightIcon"
      :left-icon="leftIcon"
      :rules="rules"
      @blur="onBlur"
      @focus="onFocus"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Field } from "vant";
Vue.use(Field);
export default {
  watch: {
    value(newValue, oldValue) {
      this.changeModel(newValue);
    },
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  components: {},
  props: {
    size: {
      type: String,
      default: "mini",
    },
    autosize: {
      type: Object,
      default: function () {
        return { maxHeight: 80, minHeight: 80 };
      },
    },
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: Number,
      default: 300,
    },
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showWordLimit: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    inputAlign: {
      type: String,
      default: "left",
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    modeFlag: {
      type: [String, Number],
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    leftIcon: {
      type: String,
      default: "",
    },
    rightIcon: {
      type: String,
      default: "",
    },
    rules: Array,
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    changeModel(newValue) {
      this.model = newValue;
      // 输入框的值改变时触发
      this.$emit("change", newValue);
    },
    blur() {
      this.$refs.field.blur();
    },
    focus() {
      this.$refs.field.focus();
    },
    onBlur() {
      this.filterForDOMXSS(this.model, this.modeFlag);
      this.$emit("blur");
    },
    onFocus() {
      this.$emit("focus");
    },
    change(value) {
      this.$emit("change", value);
    },

    /** 前端拦截 xss 攻击  */
    filterForDOMXSS(value, modeFlag) {
      //value是要传入的文本，而modeFlag是使用的过滤强度
      var searchStatus = false;
      var crucialWord = [
        // "javascript",
        // "eval",
        // "alert",
        // "onclick",
        // "ondblclick",
        // "onmousedown",
        // "onmouseup",
        // "onmouseover",
        // "onmouseout",
        // "onmousemove",
        // "onkeydown",
        // "onkeyup",
        // "onkeypress",
        "<script+.*>",
        "<p+.*>",
        "<a+.*>",
        "<img+.*>",
        "<h+.*>",
        "<textarea+.*>",
        "<span+.*>",
        "<div+.*>",
        "<td+.*>",
        "<tr+.*>",
        "<th+.*>",
        "<li+.*>",
        "<ul+.*>",
        "<option+.*>",
        "<select+.*>",
        "<del+.*>",
        "<ol+.*>",
        "<label+.*>",
        "<button+.*>",
        "<style+.*>",
        "<em+.*>",
        "<strong+.*>",
        "<strike+.*>",
        "<b+.*>",
        "<s+.*>",
        "<u+.*>",
        "<sub+.*>",
        "<sup+.*>",
        "<small+.*>",
        "<dl+.*>",
        "<dt+.*>",
        "<i+.*>",
        "<pre+.*>",
        "<q+.*>",
        "<blockquote+.*>",
        "<br+.*>",
        "<code+.*>",
        "<cite+.*>",
        "\\$\\(+.*\\)",
      ]; //过滤关键词，可以后续添加
      for (var i = 0; i < crucialWord.length; i++) {
        var check = new RegExp(crucialWord[i], "gi");
        if (check.exec(value)) {
          searchStatus = true;
          break;
        }
      }
      if (searchStatus) {
        // value = value
        //   .replace(/javascript/gi, "")
        //   .replace(/eval/gi, "")
        //   .replace(/\$\(+.*\)/g, "")
        //   .replace(/alert/gi, "")
        //   .replace(/onclick/gi, "")
        //   .replace(/ondblclick/gi, "")
        //   .replace(/onmousedown/gi, "")
        //   .replace(/onmouseup/gi, "")
        //   .replace(/onmouseover/gi, "")
        //   .replace(/onmouseout/gi, "")
        //   .replace(/onmousemove/gi, "")
        //   .replace(/onkeydown/gi, "")
        //   .replace(/onkeyup/gi, "")
        //   .replace(/onkeypress/gi, "");

        if (modeFlag == 0) {
          //仅过滤标签模式
          value = value
            .replace(/<script+.*>/gi, "")
            .replace(/<p+.*>/gi, "")
            .replace(/<a+.*>/gi, "")
            .replace(/<img+.*>/gi, "")
            .replace(/<h+.*>/gi, "")
            .replace(/<textarea+.*>/gi, "")
            .replace(/<span+.*>/gi, "")
            .replace(/<div+.*>/gi, "")
            .replace(/<td+.*>/gi, "")
            .replace(/<tr+.*>/gi, "")
            .replace(/<th+.*>/gi, "")
            .replace(/<li+.*>/gi, "")
            .replace(/<ul+.*>/gi, "")
            .replace(/<option+.*>/gi, "")
            .replace(/<select+.*>/gi, "")
            .replace(/<del+.*>/gi, "")
            .replace(/<ol+.*>/gi, "")
            .replace(/<label+.*>/gi, "")
            .replace(/<button+.*>/gi, "")
            .replace(/<style+.*>/gi, "")
            .replace(/<em+.*>/gi, "")
            .replace(/<strong+.*>/gi, "")
            .replace(/<strike+.*>/gi, "")
            .replace(/<b+.*>/gi, "")
            .replace(/<s+.*>/gi, "")
            .replace(/<u+.*>/gi, "")
            .replace(/<sub+.*>/gi, "")
            .replace(/<sup+.*>/gi, "")
            .replace(/<small+.*>/gi, "")
            .replace(/<dl+.*>/gi, "")
            .replace(/<dt+.*>/gi, "")
            .replace(/<i+.*>/gi, "")
            .replace(/<pre+.*>/gi, "")
            .replace(/<q+.*>/gi, "")
            .replace(/<blockquote+.*>/gi, "")
            .replace(/<br+.*>/gi, "")
            .replace(/<code+.*>/gi, "")
            .replace(/<cite+.*>/gi, "");
        } else if (modeFlag == 1) {
          //过滤符号模式
          value = value
            .replace(/</g, "")
            .replace(/>/g, "")
            .replace(/&lt;/gi, "")
            .replace(/gt&;/gi, "")
            .replace(/\$\(\)/g, "");
        } else if (modeFlag == 2) {
          //全过滤模式（不推荐）
          value = value.replace(/<\w+.*>/g, "");
        }
        this.$toast({
          message: "您输入的内容中包含非法字符串，已为您自动过滤",
          duration: 2000,
        });
        // this.$toast(
        //   "您输入的内容中包含非法字符串，已为您自动过滤"
        // );
      }
      this.model = value;
    },
  },
};
</script>

<style lang="less"></style>
