// const protocol = 'http'
const protocol = "https";
// const host = "future.zhuneng.cn";
// const host = "dev.zhuneng.cn";
// const host = "gxq.zhuneng.cn:444";//生产
const host = "lg.zhuneng.cn"; //研发
// const host = "39.103.230.251";
const origin = protocol + "://" + host;
// const pdfOrigin = `https://gxq.zhuneng.cn:444/pdfproxy`; //pdf阅读器入口 生产
const pdfOrigin = `https://test.zhuneng.cn/pdfproxy`; //测试

module.exports = {
  origin,
  pdfOrigin,
};
